<template>
  <div>
    <Pane />

          <a-form
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
    <a-card class="container">

      <div class="title">
        基本情况
      </div>

        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="报告编号">
              <a-input
                v-decorator="['number', 
                  { rules: [{ required: true, message: '请输入' }] },]"
              />
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="报告单位">
                        <CompanySelector @change="(company) => (selectedCompany = company)">
                  <a-button block style="margin-top: 4px; text-align: left" class="ellipsis">
                    {{ selectedCompany.name }}
                  </a-button>
                </CompanySelector>
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="报告时间">
                     <a-date-picker
                placeholder=""
                v-decorator="[
                  'reportTime',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="接收人">
              <a-input
                v-decorator="['receiverName', 
                  { rules: [{ required: true, message: '请输入' }] },]"
              />
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>

    <a-card class="container">
        <div class='title'>
              报告人
          </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="姓名">
              <a-input
                v-decorator="['reporterName', 
                  { rules: [{ required: true, message: '请输入' }] },]"
              />
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="联系电话">
              <a-input
                v-decorator="['reporterMobile', 
                  ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

    </a-card>

        <a-card class="container">
        <div class='title'>
              现场负责人
          </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="姓名">
              <a-input
                v-decorator="['siteManagerName', 
                  ]"
              />
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="联系电话">
              <a-input
                v-decorator="['siteManagerMobile', 
                  ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>

            <a-card class="container">
        <div class='title'>
              事故信息
          </div>
        <a-row>


        <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故类别">
              <a-select
                style="width: 100%"
                v-decorator="[
                  'accCategory',
                ]"
              >
                <a-select-option
                  v-for="item in acc_category"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

            <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故级别">
              <a-select
                style="width: 100%"
                v-decorator="[
                  'accLevel',
                ]"
              >
                <a-select-option
                  v-for="item in acc_level"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故名称">
              <a-input
                v-decorator="['accName', 
                  ]"
              />
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故发生地点">
              <a-input
                v-decorator="['accLocation', 
                  
                  ]"
              />
            </a-form-item>
          </a-col>
                           <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故发生时间">

                                   <a-date-picker
                placeholder=""
                v-decorator="[
                  'accTime',
             
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
                           <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="事故发生单位">
              <a-input
                v-decorator="['accOrg', 
                ]"
              />
            </a-form-item>
          </a-col>


          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="事故经过简要描述" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea
                v-decorator="[
                  'accDesc',
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>

        <a-card class="container">
        <div class='title'>
              人员伤亡情况
          </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="死亡人数">
              <a-input-number
                v-decorator="['deadNum', 
             ]"
              />
            </a-form-item>
          </a-col>
                   <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="失踪人数">
                      <a-input-number

                v-decorator="['missingNum', 
                ]"
              />
            </a-form-item>
          </a-col>
                  <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="重伤人数">
                    <a-input-number
                v-decorator="['injuredNum', 
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>


        <a-card class="container">
        <div class='title'>
              经济损失情况
          </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="直接经济损失(万)">
              <a-input-number
                v-decorator="['directEconomicLoss', 
                ]"
              />
            </a-form-item>
          </a-col>

                <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="其他经济损失情况" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea
                v-decorator="[
                  'otherEconomicLoss',
                
                ]"
              />
            </a-form-item>
          </a-col>
    
    
        </a-row>
    </a-card>

            <a-card class="container">
        <div class='title'>
                其他
          </div>
        <a-row>

                <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="目前已经采取的控制措施" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea
                v-decorator="[
                  'controlMeasures',
               
                ]"
              />
            </a-form-item>
          </a-col>
                    <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="备注" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea
                v-decorator="[
                  'remark',
               
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
    </a-card>

        <div class="footer center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit" type="primary" :loading="loading">创建</a-button>
          </a-space>
        </div>
      </a-form>

  </div>
</template>
  
<script>
import CompanySelector from "@/components/company-selector";
import { mapGetters } from 'vuex'
import request from "@/api/request";

function add(data) {
  return request({
    url: "/office-service/quality/acc/report/add",
    method: "post",
    data
  });
}
export default {

  name: 'accidentAdd',
  
  components: {
    CompanySelector
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selectedCompany: {},
      loading: false,
    };
  },


  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    acc_level() {
      return this.findDataDict("acc_level");
    },

    acc_category() {
      return this.findDataDict("acc_category");
    },

  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          if (!this.selectedCompany.name) {
            this.$message.error("请选择报告单位");
            return;
          }

          this.loading = true;

          add({
            ...values,
            deptId: this.selectedCompany.id,
            deptName: this.selectedCompany.name,
            reportTime: values.reportTime.format("YYYY-MM-DD"),
            accTime: values.accTime ? values.accTime.format("YYYY-MM-DD") : undefined,
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
              this.$message.error("请填写必填项目");
        }
      });
    }
  }
};
</script>
  
  
<style lang="less" scoped>
.title {
  font-size: 14px;
  color: #1890ff;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: 8px;
}
.footer {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>